import React, { useRef } from "react";
import otpVerificationNew from "../../../assets/images/otpVerificationNew.png";
import Button from "../../button/button";
import { MEDIUM } from "../../buttonSize";
import Styles from "./haveZpin.module.scss";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../buttonStyles";
import ErrorMessage from "../../error-message/errorMessage";
import { checkIsEmpty, isValidZpin } from "../../../utils/validation";

function HaveZpin({
  updateZpinFlow,
  onCancel,
  otpFlowInstead,
  isLoading,
  handleZpinAuthHave,
}: any) {
  const lengthOfZpin = 4;
  const [zpin, setZpin] = React.useState("");
  const inputs: any = Array(lengthOfZpin)
    .fill(null)
    .map((_, index) => index);
  const inputRef = useRef<any>([]);

  let currentActiveInputForNormal = 0;
  currentActiveInputForNormal = 0;
  const [error, setError] = React.useState("");

  const [validZpin, setValidZpin] = React.useState(false);
  const [invalidZpin, setInvalidZpin] = React.useState(false);
  const [wrongNumberOfAttempts, setWrongNumberOfAttempts] = React.useState(0);

  function validateZpin(e: any) {
    e.preventDefault();
    if (checkIsEmpty(zpin)) {
      setError("ZPIN cannot be empty");
      return setInvalidZpin(true);
    }
    handleZpinAuthHave(zpin, null, setInvalidZpinError);
  }

  function handleZpin(zPinValue: string, index: number) {
    // To check whether ZPIN is numeric or not
    if (!isValidZpin(zPinValue) && zPinValue !== "") {
      inputRef.current[index].value = "";
      setError("ZPIN should be numeric");
      return setInvalidZpin(true);
    }
    // To check whether the user is removing the entered ZPIN.
    if (zPinValue === "") {
      currentActiveInputForNormal = index - 1;
      const currentInput = inputRef.current[currentActiveInputForNormal];
      // To check whether the focused input is not less than 0
      if (currentActiveInputForNormal < 0) {
        return;
      }
      // To check whether the user is clearing the last input
      // if clears the last input than we remove two values else one
      if (zpin.length === lengthOfZpin) {
        // const nextInput = inputRef.current[currentActiveInputForNormal + 1];
        const str = zpin.substr(0, zpin.length - 2);
        setZpin(str);
      } else {
        const str = zpin.substr(0, zpin.length - 1);
        setZpin(str);
      }
      // Remove disable state.
      currentInput.disabled = false;
      currentInput.value = "";
    } else {
      // Only accepts 4 digits in ZPIN
      if (zpin.length > lengthOfZpin - 1) {
        return;
      }
      setZpin((zpin: string) => `${zpin + zPinValue}`);
      const currentInput = inputRef.current[index];
      // Add disable state once input is filled.
      zpin.length < lengthOfZpin - 1 && (currentInput.disabled = true);
      currentActiveInputForNormal = index + 1;
    }
    // To check the verify button enable and disable
    if (zpin.length === lengthOfZpin - 1) {
      setValidZpin(true);
    } else {
      setValidZpin(false);
    }
    const nextInput = inputRef.current[currentActiveInputForNormal];
    // To Check whether the focus does not exceed the number of input
    // else throw error of index out of bound
    if (
      currentActiveInputForNormal < lengthOfZpin &&
      currentActiveInputForNormal >= 0
    ) {
      nextInput.focus();
    }
  }

  function setInvalidZpinError() {
    if (wrongNumberOfAttempts === 2) {
      return otpFlowInstead(true);
    }
    setWrongNumberOfAttempts(wrongNumberOfAttempts + 1);
    setInvalidZpin(true);
    setError("Invalid zpin, please re-enter");
    resetAllInputs();
    setTimeout(() => {
      setInvalidZpin(false);
      setError("");
    }, 2000);
  }

  function resetAllInputs() {
    for (let i = 0; i < lengthOfZpin; i++) {
      const currentInput = inputRef.current[i];
      currentInput.value = "";
      currentInput.disabled = false;
    }
    inputRef.current[currentActiveInputForNormal].focus();
    setZpin("");
  }

  return (
    <>
      <form>
        <div className="py-3 text-center">
          <img
            src={otpVerificationNew}
            alt="otp-verification"
            style={{ width: "100px" }}
          />
        </div>
        <div className="py-2 px-4">
          <p className={`mb-0 ${Styles.headingText}`}>Enter 4-digit ZPIN</p>
        </div>
        <div style={{ width: "300px", margin: "0 auto" }} className="py-3">
          <div
            className={`py-2 d-flex align-items-center justify-content-center ${
              invalidZpin ? "wrongOtp" : ""
            }`}
          >
            {inputs.map((input: any, index: number) => {
              return (
                <div key={input} className="px-1">
                  <input
                    ref={(input) => (inputRef.current[index] = input)}
                    id={`z-pin-existing-${index}`}
                    type="password"
                    className={`${Styles.commonInputClass} ${
                      Styles.formControl
                    } ${invalidZpin ? Styles.wrongOtp : ""}`}
                    onClick={() => {
                      setInvalidZpin(false);
                      setError("");
                    }}
                    maxLength={1}
                    autoComplete="off"
                    autoFocus={currentActiveInputForNormal === index}
                    style={{ width: "40px", top: 50, left: 50 }}
                    onKeyUp={(event: any) => {
                      const zpinValue = event.target.value;
                      handleZpin(zpinValue, index);
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className="px-1 d-flex align-items-center justify-content-center flex-column">
            {error && <ErrorMessage forPopup={true}>{error}</ErrorMessage>}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center px-4">
          <div className="px-2">
            <Button
              type="submit"
              hoveredStyle={primaryButtonHoverStylePopup}
              style={primaryButtonStylePopup}
              isLoading={isLoading}
              disabled={!validZpin}
              onClick={validateZpin}
              size={MEDIUM}
            >
              Submit
            </Button>
          </div>
        </div>

        <div className="d-flex flex-column align-items-center justify-content-center">
          <p className={`${Styles.subText} flex-grow-1`}>
            Forgot ZPIN?
            <span
              className={Styles.createZpinLink}
              onClick={() => {
                updateZpinFlow();
              }}
            >
              {" "}
              Create New
            </span>
          </p>

          <p
            className={`${Styles.forOTPAuth} flex-grow-1`}
            onClick={otpFlowInstead}
          >
            Use OTP for Authentication
          </p>
        </div>

        <div className="py-3 d-flex justify-content-center">
          <div className="d-inline-flex">
            <p
              className={`mb-0 ${Styles.cancelText}`}
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </p>
          </div>
        </div>
      </form>
    </>
  );
}

export default HaveZpin;
